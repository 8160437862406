import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SocialMedia from "../components/SocialMedia"
import downloadIcon from "../../content/assets/icons/download.svg"
import "./download.css"

export default function Report({ location }) {
  return (
    <Layout location={location}>
      <SEO 
        title="Acesse o relatório na íntegra"
        description="Estudo produzido pela Transparência Internacional - Brasil e o WWF-Brasil mostra mudanças que o Brasil precisa implementar para prevenir grandes obras de infraestrutura de ilegalidades e proteger a floresta e as comunidades locais de impactos socioambientais negativos."
        og_description="Acesse o relatório produzido pela Transparência Internacional - Brasil e o WWF-Brasil."
      />
      <div
        className="embed-container"
        data-page-width="453"
        data-page-height="640"
        id="ypembedcontainer"
      >
        <iframe
          src="https://www.yumpu.com/pt/embed/view/OuQofS6H5JtdbBC2"
          frameBorder="0"
          allowFullScreen={true}
          allowtransparency={true}
          title="Yumpu"
        ></iframe>
        <br />
        <a
          href="https://www.yumpu.com/pt/document/view/65670362/grandes-obras-na-amazonia-corrupcao-e-impactos-socioambientais"
          title="Grandes Obras na Amazônia, corrupção e impactos socioambientais"
          target="_blank"
          rel="noreferrer"
        >
          Grandes Obras na Amazônia, corrupção e impactos socioambientais
        </a>
      </div>
      <script src="https://players.yumpu.com/modules/embed/yp_r_iframe.js"></script>

      <div className="section-download">
        <a className="btn" target="_blank" rel="noreferrer" href="https://comunidade.transparenciainternacional.org.br/grandes-obras-na-amazonia-corrupcao-impactos-socioambientais" download>
          <img className="icon" src={downloadIcon} alt="Ícone" />
          Baixar relatório
        </a>
      </div> 

      <SocialMedia />
    </Layout>
  )
}
