import React, { useState, useEffect } from "react"

import facebookIcon from "../../../content/assets/icons/facebook-icon.svg"
import twitterIcon from "../../../content/assets/icons/twitter-icon.svg"
import linkedinIcon from "../../../content/assets/icons/linkedin-icon-azul.svg"
import whatsappIcon from "../../../content/assets/icons/wpp-icon.svg"
import emailIcon from "../../../content/assets/icons/email-icon.svg"

import "./styles.css"

const SocialMedia = () => {
  const [atualURL, setURL] = useState(0)

  useEffect(() => {
    setURL(window.location.href)
  }, [])
  const bodyEncoded = encodeURIComponent(
    "Grandes obras de infraestrutura são apresentadas por governos e setor privado como necessárias para o desenvolvimento social e econômico do Brasil, sobretudo da Amazônia. Ao mesmo tempo, muitas vezes acabam se tornando alvo de corrupção e vetores para problemas socioambientais.\n\nComo impedir que esse histórico continue se repetindo no futuro? No relatório “Grandes Obras na Amazônia, corrupção e impactos socioambientais”, WWF-Brasil e Transparência Internacional – Brasil apresentam propostas de reformas e boas práticas que podem fazer com que o desenvolvimento econômico caminhe lado a lado com a luta contra a corrupção e a responsabilidade socioambiental.\n\n https://bit.ly/3wHd8xB"
  )
  return (
    <section className="socialMedia">
      <h3 className="subheading-green">Compartilhe</h3>
      <ul className="socialMedia--icons">
        <li>
          <a
            className="social-link"
            target="_blank"
            rel="noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=${atualURL}`}
          >
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </li>
        <li>
          <a
            className="social-link"
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=Grandes obras de infraestrutura podem ser vetores de corrupção e causar impactos socioambientais?%0A%0AConfira nesse estudo de @WWF_Brasil e @TI_InterBr por que essa afirmação é verdadeira e como podemos impedir que esse histórico continue a acontecer. https://bit.ly/3wRurfR`}
          >
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            className="social-link"
            target="_blank"
            rel="noreferrer"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${atualURL}`}
          >
            <img src={linkedinIcon} alt="Linkedin" />
          </a>
        </li>
        <li>
          <a
            className="social-link"
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?text=Você sabia que *grandes obras de infraestrutura podem significar casos de corrupção e um histórico de impacto ambiental*?%0A%0AConfira no relatório “Grandes Obras na Amazônia, corrupção e impactos socioambientais” seis exemplos que mostram como ilegalidades e graves danos ao meio ambiente caminharam junto de grandes obras e o que pode ser feito para evitar que isso aconteça no futuro.%0A%0AAcesse: https://bit.ly/3wH3POa`}
          >
            <img src={whatsappIcon} alt="Whatsapp" />
          </a>
        </li>
        <li>
          <a
            className="social-link"
            target="_blank"
            rel="noreferrer"
            href={`mailto:?subject=Grandes obras na Amazônia, corrupção e impactos socioambientais&body=${bodyEncoded}`}
          >
            <img src={emailIcon} alt="E-mail" />
          </a>
        </li>
      </ul>
    </section>
  )
}

export default SocialMedia
